.swal2-popup.swal2-toast {
  box-shadow: none !important;
  padding: 13px !important;
  border-radius: 0 !important;
}

.swal2-popup.swal2-toast .swal2-content {
  text-align: center !important;
}

.swal2-popup.swal2-toast .swal2-html-container {
  padding: 1px !important;
}

.swal2-container {
  padding: 0 !important;
}

pre {
  color: #f7f7f7;
  font-size: 14px;
  box-shadow: none;
  font-family: sans-serif;
  font-weight: 700;
}
