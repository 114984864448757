@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "NeueHaasDisplay";
  src: local("NeueHaasDisplay"),
    url("./assets/font/NeueHaasDisplay-Roman.ttf") format("truetype");
}

@font-face {
  font-family: "NeueHaasDisplayMediu";
  src: local("NeueHaasDisplay"),
    url("./assets/font/NeueHaasDisplay-Mediu.ttf") format("truetype");
}

@font-face {
  font-family: "NeueHaasDisplayBold";
  src: local("NeueHaasDisplay"),
    url("./assets/font/NeueHaasDisplay-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, NeueHaasDisplayMediu, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
